import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';

import alex from '../../assets/image_folder/alex.jpg';
import ultraleap from '../../assets/logos/ultraleap-logo.png';
import graphcore from '../../assets/logos/gc-logo-2.png';
import hpe from '../../assets/logos/hpe-logo.png';
import agilic from '../../assets/logos/agilic.png';

import LaunchIcon from '@mui/icons-material/Launch';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function ExperienceComp() {
    const theme = useTheme();
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

    let titleStyle = {variant: 'h4'};
    let subStyle = {variant: 'h5'};
    let avatarSize = {width: 400, height: 400};

    if(lgUp)
    {
        titleStyle = {variant: 'h1'};
        subStyle = {variant: 'h3'};
        avatarSize = {width: 400, height: 400};
    }

    const lgAccordian = useMediaQuery(theme => theme.breakpoints.up('lg'));
//ochre.light
    return (
       <Box sx={{ color: 'inherit', bgcolor: 'inherit' }}>
            <Accordion sx={{ color: 'inherit', bgcolor: 'inherit' }}>
            <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header" >
                <Box component="img" sx={{ height: 50, width: 50, mx: 2 }} alt="Ultraleap" src={ultraleap}/>
                <Stack direction="column" alignItems="left" gap={1}>
                    <Typography variant="h4" align='left'>Ultraleap</Typography>
                    <Typography variant="h5" sx={{ display: { xs: 'none', md: 'block' } }} align='center'>Software Engineer, December 2022 - July 2024</Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Stack direction="column" alignItems="left" gap={1}>
                    <Box>
                        <Typography variant="h5" sx={{ display: { xs: 'block', md: 'none' } }} align='left'>Software Engineer</Typography>
                        <Typography variant="h6" sx={{ display: { xs: 'block', md: 'none' } }} align='left'>December 2022 - July 2024</Typography>
                    </Box>
                    <Typography variant="body">
                        As part of the tracking platforms team I write and develop features for our Hand Tracking platform for use in AR and VR applications on an array of operating systems and hardware. I've been apart of writing applications and examples that use our handtracking, external services to our platform for licensing infrastructure and greenfield projects exploring new technologies. More recently I've been invovled in the work with event cameras and our demo to bring gestural interaction to AR glasses. I was responsible for our Bluetooth communication over GATT, applications and services used in the demo.
                    </Typography>
                </Stack>
                <Box gap={1} p={1}>
                    <Chip label="C++" variant="filled" sx={{ bgcolor: theme.chip }} component="a"/>
                    <Chip label="Python"variant="filled" sx={{ bgcolor: theme.chip }} component="a"/>
                    <Chip label="MacOS" variant="filled" sx={{ bgcolor: theme.chip }} component="a"/>
                    <Chip label="Linux" variant="filled" sx={{ bgcolor: theme.chip }} component="a"/>
                    <Chip label="Android" variant="filled" sx={{ bgcolor: theme.chip }} component="a"/>
                    <Chip label="AWS" variant="filled" sx={{ bgcolor: theme.chip }} component="a"/>
                    <Chip label="Bluetooth" variant="filled" sx={{ bgcolor: theme.chip }} component="a"/>
                </Box>
            </AccordionDetails>
            </Accordion>
            <Accordion sx={{ color: 'inherit', bgcolor: 'inherit' }}>
                <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
                    <Box component="img" sx={{ height: 50, width: 50, mx: 2 }} alt="Graphcore" src={graphcore}/>
                    <Stack direction="column" alignItems="left" gap={1}>
                        <Typography variant="h4" align='left'>Graphcore</Typography>
                        <Typography variant="h5" sx={{ display: { xs: 'none', md: 'block' } }} align='center'>AI Engineer, May 2021 - November 2022</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack direction="column" alignItems="left" gap={1}>
                        <Box>
                            <Typography variant="h5" sx={{ display: { xs: 'block', md: 'none' } }} align='left'>AI Engineer</Typography>
                            <Typography variant="h6" sx={{ display: { xs: 'block', md: 'none' } }} align='left'>May 2021 - November 2022</Typography>
                        </Box>
                        <Typography variant="body">
                            In the product support team as part of a multi-disciplined team working in machine learning I identified, debuged, solved issues and improved the customer experience with the Graphcore Intelligence Processing Unit. My responsibilities included interfacing with customers to solve their issues, submitting changes & fixes to core repositories of the Poplar SDK, writing tutorial material, developing internal software tools and documentation of the product. As part of this role I was asked to champion our system issues, taking responsibility in an unfamiliar discipline to fill the gap in our team's expertise. I was able to improve on multiple user processes and document our product better for customers and our own engineers. I detailed debugging techniques and delivered presentations and coaching for our engineers to gain more knowledge in our product to boost our team's ability and poise new hires in this role to succeed.
                        </Typography>
                    </Stack>
                    <Box gap={1} p={1}>
                        <Chip label="C++" variant="filled" sx={{ bgcolor: theme.chip }}/>
                        <Chip label="Python" variant="filled" sx={{ bgcolor: theme.chip }}/>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ color: 'inherit', bgcolor: 'inherit' }}>
                <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
                    <Box component="img" sx={{ height: 50, width: 50, mx: 2 }} alt="Hewlett Packard Enterprise"dh src={hpe}/>
                    <Stack direction="column" alignItems="left" gap={1}>
                        <Typography variant="h4" align='left'>Hewlett Packard Enterprise</Typography>
                        <Typography variant="h5" sx={{ display: { xs: 'none', md: 'block' } }} align='center'>Software Engineer, July 2015 - May 2021</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack direction="column" alignItems="left" gap={1}>
                        <Box>
                            <Typography variant="h5" sx={{ display: { xs: 'block', md: 'none' } }} align='left'>Software Engineer</Typography>
                            <Typography variant="h6" sx={{ display: { xs: 'block', md: 'none' } }} align='left'>July 2015 - May 2021</Typography>
                        </Box>
                        <Typography variant="body">
                            In the Deduplication team I developed features, performed investigations and resolved issues with our high performance and multithreaded deduplication engine, I started as an intern in 2015 and developed into a core member of the team, focusing on integrity features and support tools.
                        </Typography>
                    </Stack>
                    <Box gap={1} p={1}>
                        <Chip label="C++" variant="filled" sx={{ bgcolor: theme.chip }}/>
                        <Chip label="Python" variant="filled" sx={{ bgcolor: theme.chip }}/>
                        <Chip label="Linux" variant="filled" sx={{ bgcolor: theme.chip }}/>
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ color: 'inherit', bgcolor: 'inherit' }}>
                <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
                    <Box component="img" sx={{ height: 50, width: 50, mx: 2 }} alt="Agilic Ltd"dh src={agilic}/>
                    <Stack direction="column" alignItems="left" gap={1}>
                        <Typography variant="h4" align='left'>Agilic Ltd</Typography>
                        <Typography variant="h5" sx={{ display: { xs: 'none', md: 'block' } }} align='center'>Software Intern, May 2014 - September 2014</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack direction="column" alignItems="left" gap={1}>
                        <Box>
                            <Typography variant="h5" sx={{ display: { xs: 'block', md: 'none' } }} align='left'>Software Intern</Typography>
                            <Typography variant="h6" sx={{ display: { xs: 'block', md: 'none' } }} align='left'>May 2014 - September 2014</Typography>
                        </Box>
                        <Typography variant="body">
                            Between my first and second year of univeristy I worked at Agilc Ltd helping to develop their Raspberry Pi Educational robots. I prototyped the software for the newly created Tiddlybot. This experience was very hands on and by the end of the internship I'd developed a working API for the tiddlybot, added significant features to the PiBot and produced product demonstrations for both. I wasn't just writing code, I was helping to develop a product that lead to a succesful kickstarter campaign.
                            <Box>
                                <Link href="https://www.kickstarter.com/projects/1320310506/tiddlybot-fun-and-simple-raspberry-pi-robot" color="text.primary" >
                                    <LaunchIcon sx={{ fontSize: 32 }}/>{'Tiddlybot Kickstarter'}
                                </Link>
                            </Box>
                        </Typography>
                    </Stack>
                    <Box gap={1} p={1}>
                        <Chip label="Python" variant="filled" sx={{ bgcolor: theme.chip }}/>
                        <Chip label="Linux" variant="filled" sx={{ bgcolor: theme.chip }}/>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}