import React from 'react';
import Link from '@mui/material/Link';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import Collapse from '@mui/material/Collapse';
import Fade from '@mui/material/Fade';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';

import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import alexgraycv from '../../assets/Alexander_Gray_2024.pdf';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export default function LinkLine() {
    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    let linkSize = { fontSize: 32 };
    
    const theme = useTheme();
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
    if(lgUp)
    {
        linkSize = { fontSize: 32 };
    }

  return (
    <div>
	        <Stack direction="row" alignItems="left" gap={1}>
	           <CopyToClipboard text={"alexgray1415@gmail.com"}>
    	            <Link href='#' onClick={handleClick} color="inherit" sx={{linkSize}} >
    	                <EmailIcon sx={{ linkSize }}/>
    	            </Link>
    	        </CopyToClipboard>
                <Link href="https://www.linkedin.com/in/mralexgray/" color="inherit" sx={{linkSize}} >
            		<LinkedInIcon sx={{ linkSize }}/>
                </Link>
                <Link href="https://github.com/felix1415" color="inherit" sx={{linkSize}} >
                    <GitHubIcon sx={{ linkSize }}/>
                </Link>
                <Link href={alexgraycv} color="inherit" sx={{linkSize}} >
                    <PictureAsPdfIcon sx={{ linkSize }}/>
                </Link>
            </Stack>    

            <Collapse in={open}> 
                <Fade
                   in={open} //Write the needed condition here to make it appear
                   timeout={{ enter: 200, exit: 200 }} //Edit these two values to change the duration of transition when the element is getting appeared and disappeard
                   addEndListener={() => {
                     setTimeout(() => {
                       setOpen(false)
                     }, 2000);
                   }}
                   >
                   <Alert severity="info" variant="standard" className="alert" sx={{ width: 120}}>
                      <AlertTitle>Copied</AlertTitle>
                    </Alert>
                </Fade>
            </Collapse>
    </div>
  );
}
