import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import alex from '../../assets/image_folder/alex.jpg';
import bearislands from '../../assets/image_folder/bearislands.jpg';
import ism_cat from '../../assets/image_folder/ism_cat.jpg';
import ism_sp from '../../assets/image_folder/ism_stencil_printer.jpg';

import GitHubIcon from '@mui/icons-material/GitHub';
import LaunchIcon from '@mui/icons-material/Launch';

import bearislands2 from '../../assets/image_folder/bearislands2.jpg';
import cityga from '../../assets/image_folder/cityga.jpg';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function ProjectsComp() {
    const theme = useTheme();
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
    theme.spacing(2);

    let titleStyle = {variant: 'h4'};
    let subStyle = {variant: 'h5'};
    let avatarSize = {width: 400, height: 400};

    if(lgUp)
    {
        titleStyle = {variant: 'h1'};
        subStyle = {variant: 'h3'};
        avatarSize = {width: 400, height: 400};
    }

    const lgAccordian = useMediaQuery(theme => theme.breakpoints.up('lg'));

    return (
        <div>
            <Accordion sx={{ color: 'inherit', bgcolor: 'inherit' }}>
                <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header">
                    <Stack direction="column" alignItems="left" gap={1}>
                        <Typography variant="h4" align='left'>Interlaced Stencil Maker</Typography>
                        <Typography variant="h5" sx={{ display: { xs: 'none', md: 'block' } }} align='left'>A small C++ application for generating stencil OBJ files from BMP images</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack direction="column" alignItems="left" gap={1}>
                        <Box>
                            <Typography variant="h5" sx={{ display: { xs: 'block', md: 'none' } }} align='left'>A small C++ application for generating stencil OBJ files from BMP images</Typography>
                        </Box>
                        <Link href="https://github.com/felix1415/interlacedstencilmaker" color="inherit" >
                            <GitHubIcon sx={{ fontSize: 40 }}/>
                        </Link>
                        <Typography align='left'>
                            My exploration of 3D printing enabled me write open source, novel and self contained project to manufacture digital images into stencils to reimagine them creatively and physically.
                        </Typography>
                        <Grid container >
                            <Grid item xs={12} md={6} spacing={2}>
                                <Box sx={{
                                  position: 'relative',
                                  width: '95%',
                                  paddingTop: '56.25%',
                                  overflow: 'hidden',
                                  m: 2,
                                }}
                                p={1}
                              >
                                <img
                                  src={ism_sp}
                                  alt="ism"
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6} spacing={2}>
                              <Box sx={{
                                  position: 'relative',
                                  width: '95%',
                                  paddingTop: '56.25%',
                                  overflow: 'hidden',
                                  m: 2,
                                }} 
                                p={1}
                              >
                                <img
                                  src={ism_cat}
                                  alt="ism"
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                />
                              </Box>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Box gap={1} p={1}>
                        <Chip label="C++" variant="filled" sx={{ bgcolor: theme.chip }} />
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ color: 'inherit', bgcolor: 'inherit' }}>
                <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel2-content" id="panel2-header">
                    <Stack direction="column" alignItems="left" gap={1}>
                        <Typography variant="h4" align='left'>MERN Website Desgin</Typography>
                        <Typography variant="h5" sx={{ display: { xs: 'none', md: 'block' } }} align='left'>A full stack React website</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack direction="column" alignItems="left" gap={1}>
                        <Box>
                            <Typography variant="h5" sx={{ display: { xs: 'block', md: 'none' } }} align='left'>A full stack React website</Typography>
                        </Box>
                        <Link href="https://www.bearislands.com/" color="inherit"> 
                            <LaunchIcon sx={{ fontSize: 40 }}/> 
                        </Link>
                        <Typography align='left'>
                            As part of my self development, I built a MERN website for a community game server my friend operated, it included login using FusionAuth, a chat portal for players and an admin dashboard for game admins to observe and action.
                        </Typography>
                        <Box sx={{
                          position: 'relative',
                          width: '95%',
                          paddingTop: '56.25%',
                          overflow: 'hidden',
                          m: 2,
                        }} 
                        p={1}
                        >
                        <img
                          src={bearislands2}
                          alt="bearislands"
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                        </Box>
                    </Stack>
                    <Box gap={1} p={1}>
                        <Chip label="MongoDB" variant="filled" sx={{ bgcolor: theme.chip }} />
                        <Chip label="ExpressJS" variant="filled" sx={{ bgcolor: theme.chip }} />
                        <Chip label="React" variant="filled" sx={{ bgcolor: theme.chip }} />
                        <Chip label="Node" variant="filled" sx={{ bgcolor: theme.chip }} />
                    </Box>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ color: 'inherit', bgcolor: 'inherit' }}>
                <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel3-content" id="panel3-header">
                    <Stack direction="column" alignItems="left" gap={1}>
                        <Typography variant="h4" align='left'>CityEvolver</Typography>
                        <Typography variant="h5" sx={{ display: { xs: 'none', md: 'block' } }} align='left'>Using a genetic algorithm to generate potential neighbourhood designs</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack direction="column" alignItems="left" gap={1}>
                        <Box>
                            <Typography variant="h5" sx={{ display: { xs: 'block', md: 'none' } }} align='left'>Using a genetic algorithm to generate potential neighbourhood designs</Typography>
                        </Box>
                        <Link href="https://github.com/felix1415/CityEvolver" color="inherit">
                            <GitHubIcon sx={{ fontSize: 40 }}/>
                        </Link> 
                        <Typography align='left'>
                            CityEvolver is a software that generates neighbourhood designs using a genetic algorithm. The representation of each 'city' is a 3 dimiensional map of blocks, each block represents a different type of zoning. The constraints are dictated by the user by manipulating sliders to create the fitness function. This project was the software that was developed as part of my dissertation at the University of the West of England.
                        </Typography>
                        <Box sx={{
                          position: 'relative',
                          width: '95%',
                          paddingTop: '56.25%',
                          overflow: 'hidden',
                          m: 2,
                        }} 
                        p={1}
                        >
                        <img
                          src={cityga}
                          alt="cityga"
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        />
                        </Box>
                    </Stack>
                    <Box gap={1} p={1}>
                        <Chip label="LWJGL" variant="filled" sx={{ bgcolor: theme.chip }} />
                        <Chip label="OpenGL" variant="filled" sx={{ bgcolor: theme.chip }} />
                        <Chip label="Java" variant="filled" sx={{ bgcolor: theme.chip }} />
                    </Box>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}