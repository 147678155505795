import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: '#373F00',
    },
    secondary: {
      main: '#99FFB9',
    },
    chip: {
      main: '#6A7DDD'
    },
    error: {
      main: red.A400,
    },
    background: 'linear-gradient(45deg, #c98d0a 30%, #c98d0a 90%)',
    text: {
      primary: '#1B1B1E',
      secondary: '#FFF00F'
    },
  },
});

export default theme;

//background: 'linear-gradient(to right bottom, #c98d0a, #c98d0a)',
// const useStyles = makeStyles({
//   root: {
//     background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
//     border: 0,
//     borderRadius: 3,
//     boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
//     color: 'white',
//     height: 48,
//     padding: '0 30px',
//   },
// });