import * as React from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import WorkIcon from '@mui/icons-material/Work';
import TerminalIcon from '@mui/icons-material/Terminal';
import PersonIcon from '@mui/icons-material/Person';
import FolderIcon from '@mui/icons-material/Folder';
import RestoreIcon from '@mui/icons-material/Restore';
import FavoriteIcon from '@mui/icons-material/Favorite';
import LocationOnIcon from '@mui/icons-material/LocationOn';

import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import ExperienceComp from './ExperienceComp';
import ProjectsComp from './ProjectsComp';
import AboutMeComp from './AboutMeComp';
import Collapse  from '@mui/material/Collapse';
import Box  from '@mui/material/Box';
import Typography  from '@mui/material/Typography';
import Stack from '@mui/material/Stack';


export default function NavComp() {
  const [value, setValue] = React.useState('experience');

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const theme = useTheme();
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

  let iconSize = {fontSize: 50};

  return (

    <Box sx={{ m: 2 }}>
      <Box sx={{ m: 2 }}>
        <BottomNavigation value={value} onChange={handleChange} sx={{ bgcolor: 'inherit' }}>
          <BottomNavigationAction showLabel label="Experience" value="experience" sx={{ "&.Mui-selected": { color: "#fff" },}} icon={<WorkIcon sx={iconSize} />} />
          <BottomNavigationAction showLabel label="Projects" value="projects" sx={{ "&.Mui-selected": { color: "#fff" },}} icon={<TerminalIcon sx={iconSize} />} />
          <BottomNavigationAction showLabel label="About Me" value="about" sx={{ "&.Mui-selected": { color: "#fff" },}} icon={<PersonIcon sx={iconSize}/>} />
        </BottomNavigation>
      </Box>

        <Collapse in={value == "experience"} sx={{ color: 'inherit', bgcolor: 'inherit' }}>
          <ExperienceComp sx={{ color: 'inherit', bgcolor: 'inherit' }} />
        </Collapse>
        <Collapse in={value == "projects"} sx={{ color: 'inherit', bgcolor: 'inherit' }}>
          <ProjectsComp sx={{ color: 'inherit', bgcolor: 'inherit' }}/>
        </Collapse>
        <Collapse in={value == "about"} sx={{ color: 'inherit', bgcolor: 'inherit' }}>
          <AboutMeComp sx={{ color: 'inherit', bgcolor: 'inherit' }}/>
        </Collapse>
      </Box>
  );
}