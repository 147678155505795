module.exports = {
  // app info (copied from the FusionAuth admin panel)
  applicationId: 'dba0e591-d267-40b2-91ed-c33b71f4fd9d',
  tenantId: 'c4fcdc51-6888-4c67-9141-f37174e1e899',
  // our FusionAuth api key
  apiKey: 'n6E40oYnsHDACIiKqYQhziWKhYaJ42K975fHKHjSUwkc6ndpZr4fkxwi',
  SendReminderToPlayer: '16916c68-a5de-4210-b047-ef4d12d5e06f',
  SendReminderToUser: '16916c68-a5de-4210-b047-ef4d12d5e06f',

  // ports
  clientPort: 8080,
  serverPort: 9001,
  fusionAuthPort: 9011,

  apiServer: 'https://alexgray.tech/api/',
  fusionAuthServer: 'http://localhost:9011',

  mongoInstance: 'mongodb://localhost:27017/',
  mongoDatabase: 'contactAlexGray',

  websiteName: 'alexgray.tech',
  PRODUCTION: true
};
