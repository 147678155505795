import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';

// import MainBar from './NavBar';
import Home from './pages/Home';

function Copyright() {
  return (
    <Typography variant="body2" color="textPrimary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://alexgray.tech/">
        Alex Gray
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const Container = ({ children }) => {
  return (
    <Box sx={{ overflowY: 'auto', height: '100vh', position: 'relative' }}> {/* Enable scrolling, set height, and position */}
      {children}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute', // Position relative to container
    top: 0,
    left: 0,
    width: '100vw', // Cover the entire viewport width
    height: '100vh', // Cover the entire viewport height
    background: (props) => `linear-gradient(40deg, #A4B8C4 15%, #2f42f5 90%)`,
    zIndex: -1, // Place behind content
  },
}));

const Background = ({ startColor, endColor }) => {
  const classes = useStyles({ startColor, endColor });
  return <div className={classes.root} />;
};

export default function App() {

  return (
    //85714a


    //b45ef2 purple
    // b3aee8 light purple
    //86BBD8  light blue

    //A4B8C4 light grey 
    // <Box sx={{ background: 'linear-gradient(40deg, #A4B8C4 15%, #2f42f5 90%)', height: '100vh'}} >
    <div>
    <Background startColor="#007bff" endColor="#ffc107" />
    <Container>
      <Box sx={{ m: 2, overflow: 'hidden' }}>
          <Home/>
          <Copyright />
      </Box>
      </Container>
    </div>
  );
}