import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
// import { ThemeProvider } from '@mui/styles';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { alpha, styled } from '@mui/material/styles';
import App from './App';
import { orange, blue } from '@mui/material/colors';
import theme from './theme';

// 'linear-gradient(40deg, #A4B8C4 15%, #2f42f5 90%)',

const ntheme = createTheme({
  palette: {
    background: {
      default: 'linear-gradient(40deg, #A4B8C4 15%, #2f42f5 90%)',
    },
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
         body: {
           background: 'linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%)',
           backgroundRepeat: "no-repeat",
           backgroundAttachment: "fixed",
        },
      },
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={ntheme} >
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.querySelector('#root'),
);
