import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Stack from '@mui/material/Stack';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';

import football1 from '../../assets/image_folder/football1.jpg';
import football2 from '../../assets/image_folder/football2.jpg';
import football32 from '../../assets/image_folder/football32.jpg';
import football4 from '../../assets/image_folder/coach.jpg';
import electronics from '../../assets/image_folder/electronics.jpg';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function AboutMeComp() {
    const theme = useTheme();
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

    let titleStyle = {variant: 'h4'};
    let subStyle = {variant: 'h5'};
    let avatarSize = {width: 400, height: 400};

    if(lgUp)
    {
        titleStyle = {variant: 'h1'};
        subStyle = {variant: 'h3'};
        avatarSize = {width: 400, height: 400};
    }

    const lgAccordian = useMediaQuery(theme => theme.breakpoints.up('lg'));

    return (
        <Box>
            <Accordion sx={{ color: 'inherit', bgcolor: 'inherit' }}>
                <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel1-content" id="panel1-header" sx={{ color: 'inherit', bgcolor: 'inherit' }}>
                    <Stack direction="column" alignItems="left" gap={1}>
                        <Typography variant="h4" align='left'>American Football</Typography>
                        <Typography variant="h5" sx={{ display: { xs: 'none', md: 'block' } }} align='left'>UWE Bullets & Bristol Aztecs</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack direction="column" alignItems="left" gap={1}>
                        <Box>
                            <Typography variant="h5" sx={{ display: { xs: 'block', md: 'none' } }} align='left'>UWE Bullets - Bristol Aztecs</Typography>
                        </Box>
                        <Typography align='left'>
                            I've played American Football since 2014, for both the UWE Bullets while at university and the Bristol Aztecs since 2015. I've won several awards for my dedication and performance for both teams and have captained both teams.
                        </Typography>
                        <Grid container >
                            <Grid item xs={12} md={6} spacing={2}>
                                <Box sx={{
                                  position: 'relative',
                                  width: '95%',
                                  paddingTop: '56.25%',
                                  overflow: 'hidden',
                                  m: 2,
                                }}
                                p={1}
                              >
                                <img
                                  src={football1}
                                  alt="football"
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12} md={6} spacing={2}>
                              <Box sx={{
                                  position: 'relative',
                                  width: '95%',
                                  paddingTop: '56.25%',
                                  overflow: 'hidden',
                                  m: 2,
                                }} 
                                p={1}
                              >
                                <img
                                  src={football32}
                                  alt="football"
                                  style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                />
                              </Box>
                            </Grid>
                        </Grid>
                    </Stack>
                </AccordionDetails>
            </Accordion>
            <Accordion sx={{ color: 'inherit', bgcolor: 'inherit' }}>
                <AccordionSummary expandIcon={<ArrowDropDownIcon />} aria-controls="panel2-content" id="panel2-header">
                    <Stack direction="column" alignItems="left" gap={1}>
                        <Typography variant="h4" align='left'>Cheltenham Hackspace</Typography>
                        <Typography variant="h5" sx={{ display: { xs: 'none', md: 'block' } }} align='left'>3D Design, Printing and Electronics</Typography>
                    </Stack>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack direction="column" alignItems="left" gap={1}>
                        <Box>
                            <Typography variant="h5" sx={{ display: { xs: 'block', md: 'none' } }} align='left'>Learning in community</Typography>
                        </Box>
                        <Typography align='left'>
                            I'm a member of Cheltenham Hackspace where I enjoy socialising with other makers and learning new skills. I've been learning electronic design and 3D design while at the space and have been designing circuit boards and different parts for printing.
                        </Typography>
                        <Box sx={{
                              position: 'relative',
                              width: '95%',
                              paddingTop: '80%',
                              overflow: 'hidden',
                              m: 2,
                            }}
                            p={1}
                          >
                            <img
                              src={electronics}
                              alt="electronics"
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          </Box>
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
}