import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Fade from '@mui/material/Fade';
import Link from '@mui/material/Link';
import PageCounter from '../utils/PageCounter';
import ExperienceComp from './cards/ExperienceComp';
import ProjectsComp from './cards/ProjectsComp';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

import Chip from '@mui/material/Chip';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

import {CopyToClipboard} from 'react-copy-to-clipboard';


import alex from '../assets/image_folder/alex.jpg';
import alexgraycv from '../assets/Alexander_Gray_2024.pdf';
import LinkLine from './cards/LinkLine';
import NavComp from './cards/NavComp';

export default function Home() {

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen(true);
    };

    const theme = useTheme();
    const lgUp = useMediaQuery(theme.breakpoints.up('lg'));

    let titleStyle = {variant: 'h4'};
    let subStyle = {variant: 'h6'};
    let avatarSize = {width: 75, height: 75};

    if(lgUp)
    {
        titleStyle = {variant: 'h2'};
        subStyle = {variant: 'h4'};
        avatarSize = {width: 150, height: 150};
    }
    return (
            <Box my={1} m="auto">
                <Box my={1} m="auto" width="80%">
                    <Grid container >
                        <Grid item xs={12} spacing={2}>
                            <Typography component="div" variant={titleStyle.variant} color='textPrimary' align="justify">
                                <Fade in={true} timeout={{ enter: '400ms', exit: '200ms'}} style={{ 'transitionDelay': 150 }}>
                                    <Box m="auto" display='inline'>
                                        <Grid container >
                                            <Grid item xs={12} spacing={1}>
                                                <Stack direction="row" alignItems="center" gap={1}>
                                                    <Stack alignItems="center" gap={0}>
                                                        <Avatar alt="Alex Gray" src={alex} sx={avatarSize}/>
                                                        <LinkLine/>
                                                    </Stack>
                                                    <Stack alignItems="left" gap={0}>
                                                        Alex Gray
                                                        <Typography component="div" variant={subStyle.variant} color='textPrimary' align="justify">
                                                                <Fade in={true} timeout={{ enter: '400ms', exit: '200ms'}} style={{ 'transitionDelay': 300 }}>
                                                                    <Box m="auto" display='inline'> 
                                                                            Software Engineer
                                                                    </Box>
                                                                </Fade>
                                                        </Typography>
                                                    </Stack>
                                                </Stack>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Fade>
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            <Box my={1} width="75%" m="auto">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
	                    <Fade in={true} timeout={{ enter: '400ms', exit: '200ms'}} style={{ 'transitionDelay': 600 }}>
	                        <Box color="text.primary" p={2}>
	                            <Typography component="div" variant='body1' align='left'>
	                                   I'm a Software Engineer, in the UK working in C++ and Python. I've industry experience in Storage, AI and AR/VR on both software and hardware projects professionally as well as a variety of different personal projects that reinforce my skills and way of thinking.
                                </Typography>
	                        </Box>
	                    </Fade>
	                </Grid>
                    
                    <Grid item xs={12} md={12} >
                        <Fade in={true} timeout={{ enter: '400ms', exit: '200ms'}} style={{ 'transitionDelay': 750 }}>
                            <Grid container >
                                <Grid item xs={12} spacing={2}>
                                    <NavComp />
                                </Grid>
                            </Grid>
                        </Fade>
                    </Grid>
                    <Grid item xs={12} spacing={2} align="center">
                    	<Fade in={true} timeout={{ enter: '400ms', exit: '400ms'}} style={{ 'transitionDelay': 400 }}>
                            <Grid container align="center">
                                <Grid item xs={12} md={6} spacing={6} align="center">
                                    
                                </Grid>
                            </Grid>
					    </Fade>
			        </Grid>
                </Grid>
                <PageCounter name={"home"}/>
            </Box>
        </Box>
        );
}